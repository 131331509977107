import MasterService from './MasterService';


export const IncentiveService = {
    ...MasterService,
    
    getIncentiveConfig(body){ 
        return this.get("incentive/incentiveConfig",body)
    },
    getAllIncentive(body){ 
        return this.post("incentive/getAllIncentive",body)
    },
    getIncentiveSchema(body){ 
        return this.post("incentive/incentiveSchemes",body)
    },
    saveIncentive(body){ 
        return this.post("incentive/saveIncentive",body)
    },
    updateStatus(body){ 
        return this.post("incentive/updateIncentiveStatus",body)
    },
    getFinancierlist(body){ 
        return this.get("incentive/getFinancierList",body)
    },
    getRoleList(body){ 
        return this.get("incentive/getRoleList",body)
    },
    getUserList(body){ 
        return this.post("user/get-users",body)
    },
    getRoleProducts(body) {
        return this.get("incentive/getRoleProductMappingData",body)
    }
}