import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
// import { useNavigate, useParams } from 'react-router-dom';//removed warning
import { useNavigate } from 'react-router-dom';//removed warning
import FileUpload from "./FileUpload"
import { Dropdown } from 'react-bootstrap';
import ReactMultiSelectCheckboxes from '../common/ReactMultiSelectCheckboxes';
import DatePicker from "react-datepicker";
import MasterService from './../../services/MasterService';
import ls from "local-storage";
import Loader from '../common/loader';
// import { useCookies } from 'react-cookie';//removed warning
import { CSVLink } from 'react-csv';
import { ADMIN_ROLES, FORMAT_BULK_UPLOAD,TENANT } from '../../config/constant';
import DateFormat from 'dateformat';
import WithRights from '../common/WithRights'
import { useHasRight } from '../hooks/useHasRights'

const { ValueContainer, Placeholder } = components;

const UserManagement = (props) => {
  const lessFilterRef = useRef();
  const csvLink = useRef();
  // const [csvData, setCsvData] = useState(FORMAT_BULK_UPLOAD)//removed warning
  const [csvData] = useState(FORMAT_BULK_UPLOAD)
  // const { sfa_logged_user_id } = useParams();//removed warning
  const [AddPrefrencepopup, SetAddPrefrencepopup] = useState(false);
  const [dashboardCountData, setDashboardCountData] = useState([]);
  const [CityData, setCityData] = useState(null);
  const [StateData, setStateData] = useState(null);
  const [userManagementSearch, setUserManagementSearch] = useState({});
  const [AllReportingRole, SetAllReportingRole] = useState([]);
  const [ReportingRole, SetReportingRole] = useState([]);
  const [ReportingTo, SetReportingTo] = useState([]);
  const [BusinessLine, SetBusinessLine] = useState([]);
  const [StateList, SetStateList] = useState([]);
  const [CityList, SetCityList] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [dateRange1, setDateRange1] = useState([null, null]);
  const [startDate1, endDate1] = dateRange1;
  const [reportingToHide, setReportingToHide] = useState(1);
  const [errors, SetErrors] = useState({});
  const [loading, setLoading] = useState(false);
  // const [PHPSESSID, setPHPSESSID] = useCookies(['PHPSESSID']);//removed warning
  // const [loggedInUser, setLoggedInUser] = useState({});//removed warning
  // const [loggedUserId, setLoggedUserId] = useState('');//removed warning
  const [bulkUploadHistory, SetBulkUploadHistory] = useState([]);
  const { adminRoleId } = useHasRight();
  const [file, SetFile] = useState(null);

  useEffect(() => {
    let header = document.getElementsByClassName("header")
    header[0].classList.remove("hidden")
    // let tempphpSessId=(PHPSESSID && PHPSESSID.PHPSESSID)?PHPSESSID.PHPSESSID:'';    
    // let temp_logged_user_id=0;        
    // if(tempphpSessId){       
    //   if (sfa_logged_user_id) {                        
    //     ls.set('logged_user_id',sfa_logged_user_id);    
    //   }else{
    //     temp_logged_user_id=(ls.get('logged_user_id')) ? ls.get('logged_user_id') : 0;        
    //   }
    // }    
    // let logged_user_id=(sfa_logged_user_id)?sfa_logged_user_id :(temp_logged_user_id)?temp_logged_user_id:0;    
    // if(logged_user_id && logged_user_id >0){
    //   setLoggedUserId(sfa_logged_user_id);  
    //   getSfaUserLogIn(sfa_logged_user_id);
    // }else{
    //   window.location.replace(SFA_LOGIN_URL);
    // }    
    getUserDashboardList();
    getAllRoleList();
    getBusinessLineList();
    getStateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  ls.set('filter_data', {});
  ls.set('current_status', '');  
  //ls.set('inactive_pending_request', 0);
  const historyLead = useNavigate();
  const OnboardedFrom = [
    { value: 'mobile_app', label: 'App' },
    { value: 'join_us', label: 'Join Us' },
    { value: 'admin', label: 'SFA Backend' },
  ];

  const eSignStatus = [
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' },

  ];
  // const getSfaUserLoggeddata = async () => { 
  //  MasterService.post('/sfa/user/get-sfa-logged', {"PHPSESSID":(PHPSESSID && PHPSESSID.PHPSESSID)?PHPSESSID.PHPSESSID:''},{'Cookie':'PHPSESSID='+((PHPSESSID && PHPSESSID.PHPSESSID)?PHPSESSID.PHPSESSID +';':'')})
  //  .then(function (response) {

  //  })
  //  .catch(function (response) {
  //    //setLoading(false); 
  //  });
  // }
  // const getSfaUserLogIn = async (temp_logged_user_id) => {//removed warning
  //   if (temp_logged_user_id) {
  //     MasterService.post('/sfa/user/user_login', { 'userId': temp_logged_user_id })
  //       .then(function (response) {
  //         if (response.data.status == 200) {
  //           // setLoggedInUser(response.data.data);//removed warning
  //           ls.set('logged_data', response.data.data);
  //         }
  //       })
  //       .catch(function (response) {
  //         //setLoading(false); 
  //       });
  //   }
  // }
  const showModalAddPrefrencepopup = () => {
    SetAddPrefrencepopup(true);
    document.body.classList.add("overflow-hidden");
    getBulkUploadHistory()
  }
  const hideModalAddPrefrencepopup = () => {
    SetAddPrefrencepopup(false);
    SetFile(null)
    document.body.classList.remove("overflow-hidden");
  }
  const getBulkUploadHistory = async () => {
    MasterService.post('/sfa/admin/bulkUploadHistory', {})
      .then(function (response) {
        if (response.data.status == 200) {
          SetBulkUploadHistory(response.data.data);
        }
      })
      .catch(function (response) {
      });
  }
  const getUserDashboardList = async () => {
    setLoading(true);
    var userManagementSearchTemp = { ...userManagementSearch };
    MasterService.post('/sfa/user/get-users-dashboard', userManagementSearchTemp)
      .then(function (response) {
        setLoading(false);
        if (response.data.status == 200) {
          setDashboardCountData(response.data.data);
        }
      })
      .catch(function (response) {
        setLoading(false);
      });
  }
  const getBusinessLineList = async () => {
    MasterService.post('/sfa/user/business_line_list', {})
      .then(function (response) {
        if (response.data.status == 200) {
          SetBusinessLine(response.data.data);
        }
      })
      .catch(function (response) {
      });
  }
  const getAllRoleList = async () => {
    //setLoading(true);  
    MasterService.post('/sfa/user/role_list', {})
      .then(function (response) {
        //setLoading(false);  
        if (response.data.status == 200) {
          const arr = response.data.data && response.data.data.length > 0 && response.data.data.filter(i => ![53, 54, 55].includes(i.id))
          SetAllReportingRole(response.data.data);
          SetReportingRole(arr);
        }
      })
      .catch(function (response) {
        //setLoading(false);  
      });
  }
  const getStateList = async () => {
    //setLoading(true);      
    MasterService.post('/sfa/user/state_list', { "zone_id": 5 })
      .then(function (response) {
        //setLoading(false);      
        if (response.data.status == 200) {
          SetStateList(response.data.data);
        }
      })
      .catch(function (response) {
        //setLoading(false);  
      });
  }
  const handleDpdOptionChange = (sname, oValue) => {
    let statusId = oValue.id;
    var userManagementSearchTemp = { ...userManagementSearch };
    if (oValue == null)
      userManagementSearchTemp[sname] = 0;
    if (sname == 'business_line') {
      userManagementSearchTemp[sname] = oValue.business_line_id;
    } else if (sname == 'esign_status' || sname == 'user_source') {
      userManagementSearchTemp[sname] = oValue.value;
    } else {
      userManagementSearchTemp[sname] = statusId;
    }
    setUserManagementSearch(userManagementSearchTemp);
  }
  const handleRoleOptionChange = (sname, oValue) => {
    let role_id = 0;
    if (typeof oValue == 'number') {
      role_id = oValue;
    } else {
      role_id = oValue.id;
    }
    var userManagementSearchTemp = { ...userManagementSearch };
    if (oValue == null)
      userManagementSearchTemp[sname] = 0;
    else
      userManagementSearchTemp[sname] = role_id;
    //setLoading(true);      
    MasterService.post('/sfa/user/get-users', { 'role_id': role_id })
      .then(function (response) {
        //setLoading(false);      
        if (response.data.status == 200) {
          SetReportingTo(response.data.data.user_list);
        }
      })
      .catch(function (response) {
        //setLoading(false);      
      });
    setUserManagementSearch(userManagementSearchTemp);
    setReportingToHide(0);
  }
  const handleChangeMultiSel = (field_name, field_obj) => {
    let currval = [];
    var userManagementSearchTemp = { ...userManagementSearch };
    if (field_name === "state") {
      currval = field_obj.map(v => v.id);
      setStateData(field_obj);
      getCityList(currval);
    }
    if (field_name === "city") {
      currval = field_obj.map(v => v.id);
      setCityData(field_obj);
    }
    userManagementSearchTemp[field_name] = currval;
    setUserManagementSearch(userManagementSearchTemp);
  }
  const getCityList = async (state_ids) => {
    //setLoading(true);  
    MasterService.post('/sfa/user/city_list', { "state_ids": state_ids })
      .then(function (response) {
        //setLoading(false);  
        if (response.data.status == 200) {
          SetCityList(response.data.data);
        }
      })
      .catch(function (response) {
        //setLoading(false);  
      });
  }
  // const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {//removed warning
  //   if (value && value.length === 0) {
  //     return `${placeholderButtonLabel}`;
  //   } else if (value && value.length) {
  //     return `${value.length} selected`;
  //   }
  //   else
  //     return `${placeholderButtonLabel}`;
  // }

  const resetMoreFilter = (event) => {
    resetFilterForm(event);
    lessFilterRef.current.click();
  }

  const resetLessFilter = (event) => {
    resetFilterForm(event);
  }

  const resetFilterForm = event => {
    setDateRange([null, null]);
    setDateRange1([null, null]);
    setStateData([]);
    setCityData([]);
    SetReportingTo([]);
    setUserManagementSearch({});
    setReportingToHide(1);
    SetErrors({});
    setLoading(true);
    var userManagementSearchTemp = {};
    MasterService.post('/sfa/user/get-users-dashboard', userManagementSearchTemp)
      .then(function (response) {
        setLoading(false);
        if (response.data.status == 200) {
          setDashboardCountData(response.data.data);
        }
      })
      .catch(function (response) {
        setLoading(false);
      });
  }
  const handleSubmitWithoutMoreFilter = (event) => {
    submitFilterForm(event);
  }
  const handleSubmitWithMoreFilter = (event) => {
    submitFilterForm(event);
    lessFilterRef.current.click();
  }
  const submitFilterForm = event => {
    event.preventDefault();
    if (validateForm()) {
      getUserDashboardList();
    }
  }
  const validateForm = () => {
    var userManagementSearchTemp = { ...userManagementSearch };
    let formIsValid = true;
    let tempErrors = {};
    if (userManagementSearchTemp["reporting_role_id"]) {
      if (!userManagementSearchTemp["reporting_to"]) {
        tempErrors["reporting_to"] = 'Please apply the filter';
        formIsValid = false;
      }
    }
    SetErrors(tempErrors);
    return formIsValid;
  }
  const handledate = (datefield, date) => {
    var userManagementSearchTemp = { ...userManagementSearch };
    if (datefield == 'created_date') {
      setDateRange(date);
      if (date != null) {
        if (date && date.length > 0) {
          if (date[0]) {
            userManagementSearchTemp['start_created_date'] = new Date(date[0]);
          }
          if (date[1]) {
            userManagementSearchTemp['end_created_date'] = new Date(date[1]);
          }
        }
      }
    } else if (datefield == 'approved_date') {
      setDateRange1(date);
      if (date != null) {
        if (date && date.length > 0) {
          if (date[0]) {
            userManagementSearchTemp['start_approved_date'] = new Date(date[0]);
          }
          if (date[1]) {
            userManagementSearchTemp['end_approved_date'] = new Date(date[1]);
          }
        }
      }
    }
    setUserManagementSearch(userManagementSearchTemp);
  }
  const roleStatusFilterList = async (roleId, status_value) => {
    var userManagementSearchTemp = { ...userManagementSearch };
    let state_list_particular = userManagementSearchTemp.state && StateList.filter(v => userManagementSearchTemp.state.includes(v.id));
    let city_list_particular = userManagementSearchTemp.city && CityList.filter(v => userManagementSearchTemp.city.includes(v.id));
    if (roleId && roleId != '') {
      let role_list_particular = roleId && AllReportingRole.filter(v => v.id == roleId);
      userManagementSearchTemp.role_id = [];
      userManagementSearchTemp.role_id.push(roleId);
      userManagementSearchTemp.role_list_particular = role_list_particular;
    }
    userManagementSearchTemp.state_list_particular = state_list_particular;
    userManagementSearchTemp.city_list_particular = city_list_particular;
    ls.set('filter_data', userManagementSearchTemp);
    ls.set('current_status', status_value);
    historyLead('/user-management-detail');
  }
  const pendingRequestFilterList = async () => {
    var userManagementSearchTemp = { ...userManagementSearch };
    let state_list_particular = userManagementSearchTemp.state && StateList.filter(v => userManagementSearchTemp.state.includes(v.id));
    let city_list_particular = userManagementSearchTemp.city && CityList.filter(v => userManagementSearchTemp.city.includes(v.id));
    userManagementSearchTemp.state_list_particular = state_list_particular;
    userManagementSearchTemp.city_list_particular = city_list_particular;
    userManagementSearchTemp.inactive_pending_request = 1;
    ls.set('filter_data', userManagementSearchTemp);
    ls.set('current_status', '');
    //ls.set('inactive_pending_request', 1);
    historyLead('/user-management-detail');
  }
  const downloadData = () => {
    csvLink.current.link.click();
  }
  return (
    <React.Fragment>
      {loading ? <Loader /> : null}
      <div className="container-fluid">
        <div className="top-heading d-flex">
          <h1>User Management</h1>
          <div className="right-btn">
            <WithRights rightKey="bulk_upload">
              <button className="btn-line m-xs-l" onClick={showModalAddPrefrencepopup}> <i className="ic-upload"></i> Bulk Upload</button>
            </WithRights>
            <WithRights rightKey="create_user">
              <button onClick={() => historyLead("/add-user/role_details")} className="btn-line m-xs-l">Add User</button>
            </WithRights>
          </div>
        </div>
        <div className="loan-filter-bg">

          <div className="top-filter-bg user-management-fiter">
            <form>
              <div className="lead-list-filter">
                <div className="filter-filed-form">
                  <fieldset className="rangepicker-calendra">
                    <div className="material">
                      <div className="datepicker">
                        <DatePicker
                          name="created_date"
                          id="created_date"
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="yyyy-MM-dd"
                          onChange={handledate.bind(this, 'created_date')}
                          isClearable={false}
                          placeholderText={(!startDate) ? "Created Date" : ''}
                          autoComplete="off"
                          maxDate={new Date()}
                        />
                        {(startDate) ? <label data-label="Created Date" className="form-label date-label"></label> : ''}
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="rangepicker-calendra">
                    <div className="material">
                      <div className="datepicker">
                        <DatePicker
                          name="approved_date"
                          id="approved_date"
                          selectsRange={true}
                          startDate={startDate1}
                          endDate={endDate1}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="yyyy-MM-dd"
                          onChange={handledate.bind(this, 'approved_date')}
                          isClearable={false}
                          placeholderText={(!startDate1) ? "Approval Date" : ''}
                          autoComplete="off"
                          maxDate={new Date()}
                        />
                        {(startDate1) ? <label data-label="Approval Date" className="form-label date-label"></label> : ''}
                      </div>

                    </div>
                  </fieldset>
                  <fieldset className="single-select">
                    <div className="material">
                      <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        // components={{ IndicatorSeparator:() => null }} 
                        id="user_source"
                        name="user_source"
                        onChange={handleDpdOptionChange.bind(this, 'user_source')}
                        options={OnboardedFrom}
                        value={OnboardedFrom.filter(({ value }) => value === userManagementSearch.user_source)}
                        placeholder="Onboarded From"
                        className="react-select"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </fieldset>

                  <fieldset className="single-select">
                    <div className="material">
                      <Select
                        components={{ ValueContainer: CustomValueContainer }}   
                        // components={{ IndicatorSeparator: () => null }}
                        id="reporting_role_id"
                        name="reporting_role_id"
                        onChange={handleRoleOptionChange.bind(this, 'reporting_role_id')}
                        options={ReportingRole}
                        isSearchable={false}
                        value={ReportingRole.filter(({ id }) => id === userManagementSearch.reporting_role_id)}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                        placeholder="Reporting to Role"
                        className="react-select"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </fieldset>

                  <fieldset className="single-select">
                    <div className="material">
                      <Select
                        components={{ ValueContainer: CustomValueContainer }} 
                        // components={{ IndicatorSeparator: () => null }}
                        id="reporting_to"
                        name="reporting_to"
                        onChange={handleDpdOptionChange.bind(this, 'reporting_to')}
                        options={ReportingTo}
                        value={ReportingTo.filter(({ id }) => id === userManagementSearch.reporting_to)}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                        placeholder="ReportingTo"
                        className="react-select"
                        classNamePrefix="react-select"
                        isSearchable={true}
                        isDisabled={(reportingToHide == 1) ? true : false}
                      />
                    </div>
                    {
                      (errors && errors.reporting_to) ? <span className="error show absolute">{errors.reporting_to}</span> : ''
                    }
                  </fieldset>

                  <div className="more-filter">
                    <div className="filter-option">
                      <Dropdown>
                        <div className="text-btn d-flex">
                          <Dropdown.Toggle variant="success" id="dropdown-basic">

                            <span className="more-filter-txt">More Filters</span>
                            <span ref={lessFilterRef} className="leass-filter-txt">Less Filters</span>
                          </Dropdown.Toggle>

                          <div className="btn-submit-reset top-btn-none">
                            <button onClick={handleSubmitWithoutMoreFilter} type='submit' className="btn-primary">Search</button>
                            <button onClick={resetLessFilter} className="btn-reset">Reset</button>
                          </div>
                        </div>
                        <Dropdown.Menu>
                          <div className="more-filter-option">
                            <h3>More Filters</h3>
                            <div className="filter-form">

                              <fieldset className="single-select">
                                <div className="material">
                                  <Select
                                    components={{ ValueContainer: CustomValueContainer }}
                                    // components={{ IndicatorSeparator: () => null }}
                                    id="esign_status"
                                    name="esign_status"
                                    onChange={handleDpdOptionChange.bind(this, 'esign_status')}
                                    options={eSignStatus}
                                    value={eSignStatus.filter(({ value }) => value === userManagementSearch.esign_status)}
                                    placeholder="eSign Status"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                  />
                                </div>
                              </fieldset>
                              <fieldset class="multiselect-dropDown">
                                <div class="material">
                                  {/* <ReactMultiSelectCheckboxes
                                          isMulti
                                          options={StateList}
                                          placeholderButtonLabel="State"
                                          iconAfter="false"
                                          getDropdownButtonLabel={getDropdownButtonLabel}
                                          value={StateData}
                                          onChange={handleChangeMultiSel.bind(this, "state")}
                                          getOptionLabel={({ state_name }) => state_name}
                                          getOptionValue={({ id }) => id}
                                          isSearchable={true}
                                /> */}
                                  <ReactMultiSelectCheckboxes
                                    options={StateList}
                                    value={StateData}
                                    onChange={handleChangeMultiSel.bind(this, "state")}
                                    name="state"
                                    placeholder="State"
                                    isDisabled={false}
                                    isClearable={true}
                                    getOptionLabel={({ state_name }) => state_name}
                                    getOptionValue={({ id }) => id}
                                  />
                                  {(StateData && StateData.length > 0) ? <label data-label="State" className="form-label date-label"></label> : ''}
                                </div>
                              </fieldset>
                              <fieldset class="multiselect-dropDown">
                                <div class="material">
                                  {/* <ReactMultiSelectCheckboxes
                                          isMulti
                                          options={CityList}
                                          placeholderButtonLabel="City"
                                          iconAfter="false"
                                          getDropdownButtonLabel={getDropdownButtonLabel}
                                          value={CityData}
                                          onChange={handleChangeMultiSel.bind(this, "city")}
                                          getOptionLabel={({ city_name }) => city_name}
                                          getOptionValue={({ id }) => id}
                                /> */}
                                  <ReactMultiSelectCheckboxes
                                    options={CityList}
                                    value={CityData}
                                    onChange={handleChangeMultiSel.bind(this, "city")}
                                    name="city"
                                    placeholder="City"
                                    isDisabled={false}
                                    isClearable={true}
                                    getOptionLabel={({ city_name }) => city_name}
                                    getOptionValue={({ id }) => id}
                                  />
                                  {(CityData && CityData.length > 0) ? <label data-label="City" className="form-label date-label"></label> : ''}
                                </div>
                              </fieldset>
                              <fieldset className="single-select">
                                <div className="material">
                                  <Select
                                    components={{ ValueContainer: CustomValueContainer }}
                                    // components={{ IndicatorSeparator: () => null }}
                                    id="business_line"
                                    name="business_line"
                                    onChange={handleDpdOptionChange.bind(this, 'business_line')}
                                    options={BusinessLine}
                                    value={BusinessLine.filter(({ business_line_id }) => business_line_id === userManagementSearch.business_line)}
                                    getOptionLabel={({ business_line }) => business_line}
                                    getOptionValue={({ business_line_id }) => business_line_id}
                                    placeholder="Sales Team"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                  />
                                </div>
                              </fieldset>
                            </div>
                            <div className="btn-submit-reset more-filter-btn p-sm-t">
                              <button onClick={handleSubmitWithMoreFilter} type='submit' className="btn-primary">Search</button>
                              <button onClick={resetMoreFilter} className="btn-reset">Reset</button>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="user-tabs-outer">
            <h2>Users</h2>
            <div className="user-tabs">
            <ul>
                <WithRights rightKey="all_user_bucket">
                  <li >
                    {/* <label className="all-user">All Users</label> */}
                    <span>All Users</span>
                  </li>
                </WithRights>
                <WithRights rightKey="total_bucket">
                  <li>
                    <label className="total-user"> {dashboardCountData.total_user && dashboardCountData.total_user > 0 ? <a onClick={() => roleStatusFilterList('', '')}>{dashboardCountData.total_user}</a> : dashboardCountData.total_user}</label>
                    <span>Total</span>
                  </li>
                </WithRights>
                <WithRights rightKey="active_bucket">
                  <li>
                    <label className="active-user">{dashboardCountData.active_user_count && dashboardCountData.active_user_count > 0 ? <a onClick={() => roleStatusFilterList('', '1')}>{dashboardCountData.active_user_count}</a> : dashboardCountData.active_user_count}</label>
                    <span>Active</span>
                  </li>
                </WithRights>
                <WithRights rightKey="approval_pending_bucket">
                  <li>
                    <label className="inprocess-user">{dashboardCountData.approval_pending_user_count && dashboardCountData.approval_pending_user_count > 0 ? <a onClick={() => roleStatusFilterList('', '5')}>{dashboardCountData.approval_pending_user_count}</a> : dashboardCountData.approval_pending_user_count}</label>
                    <span>Approval Pending</span>
                  </li>
                </WithRights>
                <WithRights rightKey="in_progress_bucket">
                  <li>
                    <label className="inprocess-user">{dashboardCountData.inprocess_user_count && dashboardCountData.inprocess_user_count > 0 ? <a onClick={() => roleStatusFilterList('', '4')}>{dashboardCountData.inprocess_user_count}</a> : dashboardCountData.inprocess_user_count}</label>
                    <span>In Process</span>
                  </li>
                </WithRights>
                <WithRights rightKey="inactive_bucket">
                  <li>
                    <label className="inactive-user">{dashboardCountData.inactive_user_count && dashboardCountData.inactive_user_count > 0 ? <a onClick={() => roleStatusFilterList('', '0')}>{dashboardCountData.inactive_user_count}</a> : dashboardCountData.inactive_user_count}</label>
                    <span>Inactive</span>
                  </li>
                </WithRights>
                <WithRights rightKey="rejected_bucket">
                  <li>
                    <label className="inactive-user">{dashboardCountData.rejected_user_count && dashboardCountData.rejected_user_count > 0 ? <a onClick={() => roleStatusFilterList('', '3')}>{dashboardCountData.rejected_user_count}</a> : dashboardCountData.rejected_user_count}</label>
                    <span>Rejected</span>
                  </li>
                </WithRights>
                {(TENANT=="id") && <WithRights rightKey="rejected_bucket">
                  <li>
                    <label className="inactive-user">{dashboardCountData.inactive_user_request_count && dashboardCountData.inactive_user_request_count > 0 ? <a onClick={() => pendingRequestFilterList()}>{dashboardCountData.inactive_user_request_count}</a> : dashboardCountData.inactive_user_request_count}</label>
                    <span>Inactive Requests</span>
                  </li>
                </WithRights>
                }
              </ul>
            </div>
          </div>

            <React.Fragment>
              <div className='user-managemnt-table data-table'>
                <table>
                    <thead>
                        <tr>
                            <th>User Role</th>
                            <WithRights rightKey="total_user_column">
                            <th>Total Users</th>
                            </WithRights>
                            <WithRights rightKey="active_user_column">
                            <th>Active Users</th>
                            </WithRights>
                            <WithRights rightKey="in_progression_column">
                            <th>In Progress</th>
                            </WithRights>
                            <WithRights rightKey="inactive_users_column">
                            <th>Inactive Users</th>
                            </WithRights>
                            <WithRights rightKey="rejected_user_column">
                            <th>Rejected Users</th>
                            </WithRights>
                        </tr>
                    </thead>
                    <tbody>
                    {dashboardCountData && dashboardCountData.role_list && dashboardCountData.role_list.length == 0 ? <tr><td align="center" colSpan="6"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                        {
                            dashboardCountData && dashboardCountData.role_list && dashboardCountData.role_list.length != 0 && dashboardCountData.role_list.map((dashboard, k) => (
                                <tr key={k} >
                            <td>{dashboard.name}</td>
                            <WithRights rightKey="total_user_column">
                            <td>{dashboard.role_total_sum && dashboard.role_total_sum >0?<a  onClick={() =>roleStatusFilterList(dashboard.id,'')}>{dashboard.role_total_sum}</a>:dashboard.role_total_sum}</td>
                            </WithRights>
                            <WithRights rightKey="active_user_column">
                            <td>{dashboard.total_active && dashboard.total_active >0?<a  onClick={() =>roleStatusFilterList(dashboard.id,'1')}>{dashboard.total_active}</a>:dashboard.total_active}</td>
                            </WithRights>
                            <WithRights rightKey="in_progression_column">
                            <td>{dashboard.total_inprocess && dashboard.total_inprocess >0?<a  onClick={() =>roleStatusFilterList(dashboard.id,'4')}>{dashboard.total_inprocess}</a>:dashboard.total_inprocess}</td>
                            </WithRights>
                            <WithRights rightKey="inactive_users_column">
                            <td>{dashboard.total_inactive && dashboard.total_inactive >0?<a  onClick={() =>roleStatusFilterList(dashboard.id,'0')}>{dashboard.total_inactive}</a>:dashboard.total_inactive}</td>
                            </WithRights>
                            <WithRights rightKey="rejected_user_column">
                            <td>{dashboard.total_rejected && dashboard.total_rejected >0?<a  onClick={() =>roleStatusFilterList(dashboard.id,'3')}>{dashboard.total_rejected}</a>:dashboard.total_rejected}</td>
                            </WithRights>
                        </tr>                        
                            ))
                          }
                    </tbody>
                </table>
              </div>

            </React.Fragment>
          </div>
          <div className="bulk-upload-popup model-popup-outer">
            <Modal show={AddPrefrencepopup} handleClose={hideModalAddPrefrencepopup} >
              <div style={{ "display": "flex" }}>
                <div>
                  <div className="modal-header">
                    <h2>Bulk Upload</h2>
                  </div>
                  <div className="modal-body">
                    <FileUpload getBulkUploadHistory={()=>getBulkUploadHistory()} file={file}/>
                    <div class="download-txt">
                      Download input format <span onClick={() => downloadData()}>Click Here</span>

                    </div>
                    <CSVLink
                      data={csvData}
                      filename={`sample_format.csv`}
                      data-interception='off'
                      ref={csvLink}
                    >
                    </CSVLink>
                  </div>
                </div>                          
              <div style={{ width: "500px" }}>
                <div className="modal-header">
                  <h2>Upload History</h2>
                </div>
                <div className="modal-body">
                  <div className='user-managemnt-detail-table data-table'>
                    <table style={{ width: "auto" }}>
                      <thead>
                        <tr>
                          <th>Date & Time</th>
                          <th>Uploaded By</th>
                          <th>Records</th>
                          <th>Action</th>
                        </tr>

                      </thead>
                      <tbody>
                        {bulkUploadHistory && bulkUploadHistory.length > 0 && bulkUploadHistory.map((element, index) => (
                          <tr key={index}>
                            <td>{DateFormat(element.created_date, 'dd mmm yy, hh:MM TT')}</td>
                            <td className='show_ellipsis'>{element.display_name}</td>
                            <td>
                              <ul className='bulk_records'>
                                <li>{(element.total_record - element.failed_record) + " -"}</li>
                                <li className='failed_records'>{element.failed_record}</li>
                                <li>{"- " + element.total_record}</li>
                              </ul>
                            </td>
                            <td>
                              {element.csv_url ?
                                <ul class="action-btn">
                                  <li>
                                    <a href={element.csv_url} rel="noreferrer" target="_blank">
                                      <i class="ic-save_alt"></i>
                                    </a>
                                  </li>
                                </ul> : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>

      </div>
    </React.Fragment>
  )
}
//}
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className='modal-main'>
        {children}
        <button
          onClick={handleClose}
          className="close_icn"
        >
          <i className="ic-clearclose"></i>
        </button>
      </section>
    </div>
  );
};

export default UserManagement;